import React from "react";
import { trpc } from "../../../../lib/trpc";
import { useSearchParams } from "react-router-dom";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useAppSelector } from "../../../../redux/store";

export default function RemedialExpensesTrendChart() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;

  const { data, isLoading } = trpc.analytics.getRemedialExpensesTrend.useQuery(
    {
      projectId: project?.id ?? 0,
      year,
      month,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No remedial expenses trend data available for the selected period
          </div>
        </CardContent>
      </Card>
    );
  }

  // Custom tooltip formatter to show currency
  const formatTooltipValue = (value: number) => {
    return `RM ${value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Remedial Expenses Trend {data.year}</CardTitle>
        <CardDescription>
          This chart shows the trend of remedial expenses over time
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[300px] w-full"
        >
          <AreaChart
            data={data.chartData}
            margin={{ left: 12, right: 12, top: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => `RM ${value.toLocaleString()}`}
            />
            <ChartTooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="rounded-lg border bg-white p-2 shadow-sm">
                      <div className="grid gap-2">
                        <div className="flex items-center justify-between gap-2">
                          <span className="font-semibold">
                            {payload[0].payload.month}
                          </span>
                        </div>
                        {payload.map((entry) => (
                          <div
                            key={entry.name}
                            className="flex items-center justify-between gap-2"
                          >
                            <div className="flex items-center gap-2">
                              <div
                                className="h-2 w-2 rounded-full"
                                style={{ backgroundColor: entry.color }}
                              />
                              <span className="text-sm text-muted-foreground">
                                {entry.name === "inhouse"
                                  ? "Inhouse"
                                  : entry.name === "outsource"
                                  ? "Outsource"
                                  : "Total"}
                              </span>
                            </div>
                            <span className="text-sm font-medium">
                              {formatTooltipValue(entry.value as number)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Area
              type="monotone"
              dataKey="inhouse"
              stackId="1"
              stroke={data.chartConfig.inhouse.color}
              fill={data.chartConfig.inhouse.color}
              fillOpacity={0.3}
            />
            <Area
              type="monotone"
              dataKey="outsource"
              stackId="1"
              stroke={data.chartConfig.outsource.color}
              fill={data.chartConfig.outsource.color}
              fillOpacity={0.3}
            />
            <Area
              type="monotone"
              dataKey="total"
              stroke={data.chartConfig.total.color}
              fill={data.chartConfig.total.color}
              fillOpacity={0.3}
            />
            <Legend />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
