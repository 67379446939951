import React, { useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { cn } from "../../../lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { MoreVertical, Edit } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";

interface KpiCardProps {
  kpiCode: string;
  description: string;
  value: number;
  threshold: number;
  onEdit: (value: number) => void;
}

const schema = z.object({
  value: z.number().min(0).max(100),
});

type FormValues = z.infer<typeof schema>;

export const KpiCard: React.FC<KpiCardProps> = ({
  kpiCode,
  description,
  value,
  threshold,
  onEdit,
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const isAchieved = value >= threshold;
  const hasData = value > 0;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      value: value,
    },
  });

  const onSubmit = (data: FormValues) => {
    onEdit(data.value);
    setIsEditDialogOpen(false);
  };

  return (
    <>
      <Card className="w-full shadow-none break-inside-avoid relative">
        <div className="absolute top-2 right-2">
          <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger className="h-8 w-8 inline-flex items-center justify-center rounded-md hover:bg-slate-100">
                <MoreVertical className="h-4 w-4" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => setIsEditDialogOpen(true)}>
                  <Edit className="mr-2 h-4 w-4" />
                  Edit Value
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Edit KPI Value - {kpiCode}</DialogTitle>
              </DialogHeader>
              <div  className="space-y-4">
                <div>
                  <p className="text-sm text-muted-foreground mb-4">
                    {description}
                  </p>
                  <Input
                    type="number"
                    step="0.01"
                    {...register("value", {
                      valueAsNumber: true,
                      required: "Value is required",
                      min: { value: 0, message: "Value must be at least 0" },
                      max: { value: 100, message: "Value must not exceed 100" },
                    })}
                  />
                  {errors.value && (
                    <p className="text-sm text-red-500 mt-1">
                      {errors.value.message}
                    </p>
                  )}
                </div>
                <div className="flex justify-end gap-2">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setIsEditDialogOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit(onSubmit)}>Save</Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <CardContent className="flex flex-col py-4 gap-3">
          <div className="flex">
            <div className="flex flex-col">
              <p className="font-bold text-xl">{kpiCode}</p>
              <div className="text-sm text-muted-foreground">{description}</div>
            </div>
          </div>
          <Progress
            value={value}
            className="h-2"
            indicatorClassName={cn(
              "rounded-full",
              hasData
                ? isAchieved
                  ? "bg-green-600"
                  : "bg-red-600"
                : "bg-slate-400"
            )}
          />
          <div
            className={cn(
              "text-right text-xs",
              hasData
                ? isAchieved
                  ? "text-green-600"
                  : "text-red-600"
                : "text-muted-foreground"
            )}
          >
            {value.toFixed(2)}%
          </div>
        </CardContent>
      </Card>
    </>
  );
};
