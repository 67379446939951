import { useState } from "react";
import { Control, useController, Path } from "react-hook-form";

interface KPIRow {
  id?: string;
  no: string;
  kpi: string;
  piNo: string;
  performanceIndicator: string;
  target: string;
  actual: string;
  demeritPoint: string;
  demeritPointsImposed: string;
  weightage: string;
  apdValue: string;
  apdValueDeducted: string;
}

interface FormValues {
  kpiRows: KPIRow[];
}

interface EditableCellProps {
  index: number;
  fieldName: keyof Omit<KPIRow, 'id'>;
  control: Control<FormValues>;
  className?: string;
}

export function EditableCell({ index, fieldName, control, className = "" }: EditableCellProps) {
  const [isEditing, setIsEditing] = useState(false);
  
  const {
    field: { onChange, onBlur, name, ref, value }
  } = useController<FormValues>({
    control,
    name: `kpiRows.${index}.${fieldName}` as Path<FormValues>,
  });

  return (
    <td 
      className={`border p-2 text-sm text-right transition-colors ${
        isEditing ? "border-blue-400 border-2" : "border-gray-300"
      } ${className}`}
    >
      <input
        type="text"
        value={value as string}
        onChange={onChange}
        onBlur={() => {
          setIsEditing(false);
          onBlur();
        }}
        name={name}
        ref={ref}
        className="w-full bg-transparent text-right focus:outline-none"
        onFocus={() => setIsEditing(true)}
      />
    </td>
  );
} 