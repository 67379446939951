import { ReactNode } from 'react';
import KDNPDashboard from '../components/KDNPDashboard';
import DefaultDashboard from '../components/DefaultDashboard';
import FinanceDashboard from '../components/OasisDashboard';

export interface DashboardProps {
  currentTabIdx: string;
}

export interface DashboardConfig {
  component: (props: DashboardProps) => ReactNode;
  requiresData?: boolean;
}

export const dashboardRegistry: Record<string, DashboardConfig> = {
  KDNP: {
    component: KDNPDashboard,
    requiresData: false,
  },
  OS: {
    component: FinanceDashboard,
    requiresData: true,
  },
  DEFAULT: {
    component: DefaultDashboard,
    requiresData: true,
  },
};

export const getDashboardComponent = (projectCode?: string): DashboardConfig => {
  if (!projectCode) return dashboardRegistry.DEFAULT;
  return dashboardRegistry[projectCode] || dashboardRegistry.DEFAULT;
}; 