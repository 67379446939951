import React from "react";
import { trpc } from "../../../../lib/trpc";
import { PieChart, Pie, Cell, Legend, Label } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useAppSelector } from "../../../../redux/store";
import { useSearchParams } from "react-router-dom";

export function PMApportionmentStatusPieChart() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } =
    trpc.analytics.getPMApportionmentByStatus.useQuery(
      {
        projectId: project?.id ?? 0,
        month,
        year,
      },
      {
        enabled: !!project,
      }
    );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No PM checklist status data available
          </div>
        </CardContent>
      </Card>
    );
  }

  const chartDataWithPercentage = data.chartData.map((item) => ({
    ...item,
    percentage: ((item.value / data.totalPMChecklists) * 100).toFixed(1),
  }));

  return (
    <Card>
      <CardHeader>
        <CardTitle>PM Checklist Status Breakdown</CardTitle>
        <CardDescription>
          Distribution of PM checklists by status
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[300px] w-full"
        >
          <PieChart>
            <Pie
              data={chartDataWithPercentage}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              innerRadius={60}
              startAngle={90}
              endAngle={-270}
            >
              {chartDataWithPercentage.map((entry) => (
                <Cell
                  key={entry.name}
                  fill={
                    data.chartConfig[
                      entry.name.toLowerCase().replace(/\s+/g, "")
                    ]?.color
                  }
                />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-xl font-bold"
                        >
                          {data.totalPMChecklists}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          PM checklists
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
          </PieChart>
        </ChartContainer>
        <div className="mt-6">
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 place-items-center justify-center">
            <div className="col-span-2 sm:col-span-3 md:col-span-4 flex justify-center gap-4 flex-wrap">
              {chartDataWithPercentage.map((item) => (
                <div
                  key={item.name}
                  className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm transition-colors hover:bg-accent/10"
                >
                  <div className="flex items-center space-x-2">
                    <div
                      className="h-3 w-3 rounded-sm"
                      style={{
                        backgroundColor:
                          data.chartConfig[
                            item.name.toLowerCase().replace(/\s+/g, "")
                          ]?.color,
                      }}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs font-medium text-foreground">
                        {item.name}
                      </span>
                      <span className="text-sm font-semibold">
                        {item.percentage}%
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
