import { DateFilter } from "../WorkRequestAnalytics/DateFilter";
import { WorkOrderClosureRate } from "./WorkOrderClosureRate";
import { WorkOrderStatusCountSection } from "./WorkOrderStatusCountSection";
import WorkOrderTrend from "./WorkOrderTrend";
import { WorkOrderTypeApportionment } from "./WorkOrderTypeApportionment";

export function WorkOrderAnalytics() {
  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="flex items-center justify-between mt-2 col-span-4">
        <DateFilter />
      </div>
      <div className="col-span-4">
        <WorkOrderTrend />
      </div>
      <div className="col-span-2">
        <WorkOrderTypeApportionment />
      </div>
      <div className="col-span-2">
        <WorkOrderClosureRate />
      </div>

      <WorkOrderStatusCountSection />
    </div>
  );
}
