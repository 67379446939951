import React from "react";
import { trpc } from "../../../../../lib/trpc";
import { useAppSelector } from "../../../../../redux/store";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DataTable } from "@/components/react-table/DataTable";
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useColumns } from "./columns";
import { useSearchParams } from "react-router-dom";

export function ListOfFmContracts() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);
  const columns = useColumns();

  const { data, isLoading } = trpc.analytics.getFMContract.useQuery(
    {
      projectId: project?.id ?? 0,
    },
    {
      enabled: !!project,
    }
  );

  const table = useReactTable({
    data: data?.fmContractsList ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: Math.ceil((data?.fmContractsList?.length ?? 0) / pageSize),
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater({
          pageIndex,
          pageSize,
        });
        setPageIndex(newState.pageIndex);
        setPageSize(newState.pageSize);
      } else {
        setPageIndex(updater.pageIndex);
        setPageSize(updater.pageSize);
      }
    },
    manualPagination: false,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>List of FM Contracts</CardTitle>
      </CardHeader>
      <CardContent>
        <DataTable
          table={table}
          totalCount={data?.fmContractsList?.length ?? 0}
          pageIdx={pageIndex}
          rowsPerPage={pageSize}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  );
} 