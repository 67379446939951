import { useNavigate, useSearchParams } from "react-router-dom";
import { auth } from "../../../../../firebase/firebaseConfig";
import { useGetUserDataQuery } from "../../../../../redux/slices/Auth/AuthApi";
import { useNotificationControllerGetUnreadCount } from "../../../../../api/notification/notification";
import { useIsSuperadmin } from "../../../../../hooks/useRoles";
import { useAppSelector } from "../../../../../redux/store";
import {
  SidebarFooter,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "../../../../../components/ui/sidebar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../../../../../components/ui/dropdown-menu";
import { Avatar, AvatarFallback } from "../../../../../components/ui/avatar";
import { Badge } from "../../../../../components/ui/badge";
import {
  Bell,
  Building2,
  ChevronsUpDown,
  LogOut,
  Settings,
  ShieldCheck,
} from "lucide-react";
import {
  NOTIFICATION_DRAWER,
  SELECT_COMPANY_DRAWER,
} from "../../../../../components/drawers/AppDrawer";
import useAccountType, {
  COMPANY_ADMIN_ACC_TYPE,
} from "../../../../../hooks/useAccountType";

export function CerevSidebarFooter() {
  const { data: userData } = useGetUserDataQuery();
  const navigate = useNavigate();
  const isSuperAdmin = useIsSuperadmin();
  const activeCompany = useAppSelector((state) => state.root.activeCompany);
  const { data: unreadCount } = useNotificationControllerGetUnreadCount({
    query: {
      refetchInterval: 1000 * 30,
    },
  });
  const { hasAccess, isLoading } = useAccountType({
    accType: COMPANY_ADMIN_ACC_TYPE,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <SidebarFooter>
      <SidebarMenu>
        <SidebarMenuItem className="relative">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                variant="outline"
                size="lg"
                className="flex gap-4 relative bg-neutral-900 data-[state=open]:text-neutral-50"
              >
                <Avatar className="bg-primary w-8 h-8 rounded-lg bg-primary-900">
                  <AvatarFallback className="bg-transparent text-white text-xs rounded-lg">
                    {userData?.name?.substring(0, 2).toUpperCase()}
                  </AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {userData?.name}
                  </span>
                  <span className="truncate text-xs">{userData?.position}</span>
                </div>
                <ChevronsUpDown className="ml-auto size-4 text-primary" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              sideOffset={4}
              side="right"
            >
              <DropdownMenuLabel className="font-normal">
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {userData?.name}
                  </p>
                  <p className="text-xs leading-none text-muted-foreground">
                    {userData?.email}
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              {hasAccess && (
                <DropdownMenuItem onClick={() => navigate("settings")}>
                  <Settings className="mr-2 h-4 w-4" />
                  <span>Settings</span>
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                onClick={() => {
                  setSearchParams((p) => {
                    p.set("drawerType", NOTIFICATION_DRAWER);
                    return p;
                  });
                }}
              >
                <Bell className="mr-2 size-4" />
                Notification
                {unreadCount?.data?.data && unreadCount?.data?.data > 0 ? (
                  <DropdownMenuShortcut className="bg-red-600 text-white opacity-100 px-1.5 text-xs rounded-full">
                    {unreadCount.data.data > 99 ? "99+" : unreadCount.data.data}
                  </DropdownMenuShortcut>
                ) : (
                  <></>
                )}
              </DropdownMenuItem>
              {isSuperAdmin && <DropdownMenuSeparator />}
              {isSuperAdmin && (
                <DropdownMenuItem onClick={() => navigate("super-admin")}>
                  <ShieldCheck className="mr-2 h-4 w-4" />
                  <span>Super Admin</span>
                </DropdownMenuItem>
              )}
              {activeCompany && isSuperAdmin && (
                <DropdownMenuItem
                  onClick={() => {
                    setSearchParams((p) => {
                      p.set("drawerType", SELECT_COMPANY_DRAWER);
                      return p;
                    });
                  }}
                >
                  <Building2 className="mr-2 size-4" />
                  <span>Change Company</span>
                </DropdownMenuItem>
              )}
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => auth.signOut()}>
                <LogOut className="mr-2 h-4 w-4" />
                <span>Logout</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          {unreadCount?.data?.data && unreadCount?.data?.data > 0 ? (
            <Badge className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 text-xs font-bold">
              {unreadCount.data.data > 99 ? "99+" : unreadCount.data.data}
            </Badge>
          ) : (
            <></>
          )}
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarFooter>
  );
}
