import { PdfReportType } from "../../../stores/pdfReportStore/pdfReportStore";

export interface ReportConfig {
  label: string;
  value: PdfReportType;
}

export interface ProjectReportConfig {
  reports: ReportConfig[];
}

export const reportRegistry: Record<string, ProjectReportConfig> = {
  KDNP: {
    reports: [
      {
        label: "Work Order Summary",
        value: PdfReportType.WORK_ORDER_SUMMARY,
      },
    //   {
    //     label: "JKR APD Summary",
    //     value: PdfReportType.JKR_APD_SUMMARY,
    //   },
    //   {
    //     label: "JKR KPI Report",
    //     value: PdfReportType.JKR_KPI_REPORT,
    //   },
    ],
  },
  OS: {
    reports: [
      {
        label: "Work Order Summary",
        value: PdfReportType.WORK_ORDER_SUMMARY,
      },
    ],
  },
  DEFAULT: {
    reports: [
      {
        label: "Work Order Summary",
        value: PdfReportType.WORK_ORDER_SUMMARY,
      },
    ],
  },
};

export const getReportConfig = (projectCode?: string): ProjectReportConfig => {
  if (!projectCode) return reportRegistry.DEFAULT;
  return reportRegistry[projectCode] || reportRegistry.DEFAULT;
};
