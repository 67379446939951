import { useAppSelector } from "../../../../redux/store";
import AppButton from "../../../../components/AppButton";
import { Card } from "../../../../components/ui/card";
import {
  usePdfReportActions,
  usePdfReportReportType,
} from "../../../../stores/pdfReportStore/pdfReportStore";
import { cn } from "../../../../utils/util";
import { getReportConfig } from "../../config/reportRegistry";

export default function ReportPickerSection() {
  const { setReportType } = usePdfReportActions();
  const pdfReportType = usePdfReportReportType();
  const activeProj = useAppSelector((state) => state.root.activeProject);

  const reportConfig = getReportConfig(activeProj?.code);

  return (
    <div className="py-2 pr-2 h-full w-full">
      <Card className="w-full h-full p-4 flex flex-col gap-2">
        <h3 className="text-lg font-bold">Pick a report</h3>
        {reportConfig.reports.map((rt) => (
          <AppButton
            label={rt.label}
            key={rt.value}
            variant="ghost"
            className={cn("flex justify-start transition-all duration-300", {
              "bg-primary-900 text-white hover:bg-primary-500":
                pdfReportType === rt.value,
            })}
            onClick={() => {
              setReportType(rt.value);
            }}
          />
        ))}
        <div className="text-gray-400 text-sm mt-2">More to come...</div>
      </Card>
    </div>
  );
}
