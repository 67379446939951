import { WorkRequestAvgRating } from "./WorkRequestAvgRating";
import WorkRequestDayTrendTurnaroundTime from "./WorkRequestDayTrendTurnaroundTime";
import { WorkRequestHistogram } from "./WorkRequestHistogram";
import WorkRequestKpiAvgTurnAroundTime from "./WorkRequestKpiAvgTurnAroundTime";
import { WorkRequestStatusBreakdownPieChart } from "./WorkRequestStatusBreakdownPieChart";
import WorkRequestStatusMonthlyBreakdown from "./WorkRequestStatusMonthlyBreakdown";
import WorkRequestTrend from "./WorkRequestTrend";
import { DateFilter } from "./DateFilter";

export function WorkRequestAnalytics() {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between my-4">
        <DateFilter />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <WorkRequestTrend />
        <WorkRequestStatusBreakdownPieChart />
        <div className="col-span-2">
          <WorkRequestStatusMonthlyBreakdown />
        </div>
        <WorkRequestKpiAvgTurnAroundTime />
        <WorkRequestDayTrendTurnaroundTime />
        <WorkRequestAvgRating />
        <WorkRequestHistogram />
      </div>
    </div>
  );
}
