import { z } from "zod";

// ... (existing types)

export const deletePmAttachmentInputSchema = z.object({
  pmChecklistAttachmentId: z.number(),
  attachmentId: z.number(),
});

export type DeletePmAttachmentInput = z.infer<typeof deletePmAttachmentInputSchema>;

export const deletePmAttachmentOutputSchema = z.object({
  success: z.boolean(),
});

export type DeletePmAttachmentOutput = z.infer<typeof deletePmAttachmentOutputSchema>;

export const getOnePmChecklistInputSchema = z.object({
  pmChecklistId: z.number(),
});

export type GetOnePmChecklistInput = z.infer<typeof getOnePmChecklistInputSchema>;

export const getPmChecklistStatusSchema = z.object({
  projectId: z.number(),
  month: z.number().optional(),
  year: z.number().optional(),
});

export type GetPmChecklistStatusInput = z.infer<typeof getPmChecklistStatusSchema>;

export type PmChecklistStatusCount = {
  id: number;
  name: string;
  colorHex: string | null;
  order: number | null;
  count: number;
};

