import { Link } from "react-router-dom";
import { SidebarMenuItem, SidebarMenuButton, useSidebar } from "../ui/sidebar";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

interface NavigationMenuItemProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
}

export function NavigationMenuItem({ to, icon, label, isActive }: NavigationMenuItemProps) {
  const { state, isMobile } = useSidebar();
  const isCollapsed = state === "collapsed";

  const menuButton = (
    <SidebarMenuButton asChild isActive={isActive}>
      <Link to={to}>
        {icon}
        {label}
      </Link>
    </SidebarMenuButton>
  );

  if (!isCollapsed || isMobile) {
    return <SidebarMenuItem>{menuButton}</SidebarMenuItem>;
  }

  return (
    <SidebarMenuItem>
      <Tooltip>
        <TooltipTrigger asChild>
          {menuButton}
        </TooltipTrigger>
        <TooltipContent
          sideOffset={10}
          side="right" 
          align="center"
          hidden={!isCollapsed || isMobile}
        >
          {label}
        </TooltipContent>
      </Tooltip>
    </SidebarMenuItem>
  );
} 