import React from "react";
import { trpc } from "../../../../lib/trpc";
import { PieChart, Pie, Cell, Legend, Label } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useAppSelector } from "../../../../redux/store";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import AppButton from "@/components/AppButton";
import { useSearchParams } from "react-router-dom";

export function WorkOrderTypeApportionment() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [showAllMetrics, setShowAllMetrics] = React.useState(false);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } =
    trpc.analytics.getWorkOrderTypeApportionment.useQuery(
      {
        projectId: project?.id ?? 0,
        month,
        year,
      },
      {
        enabled: !!project,
      }
    );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.chartData.length === 0) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No work order type data available
          </div>
        </CardContent>
      </Card>
    );
  }

  const filteredChartData = data.chartData.filter((entry) => entry.value > 0);
  const top5ChartData = filteredChartData.slice(0, 5);

  // Add percentage calculation
  const chartDataWithPercentage = filteredChartData.map((item) => ({
    ...item,
    percentage: ((item.value / data.totalDefects) * 100).toFixed(1),
  }));

  const top5DataWithPercentage = chartDataWithPercentage.slice(0, 5);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Work Order Type Breakdown</CardTitle>
        <CardDescription>Distribution of work orders by type</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer
          config={data.chartConfig}
          className="mx-auto aspect-square max-h-[300px] w-full"
        >
          <PieChart>
            <Pie
              data={chartDataWithPercentage}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              innerRadius={60}
              startAngle={90}
              endAngle={-270}
            >
              {chartDataWithPercentage.map((entry) => (
                <Cell
                  key={entry.name}
                  fill={
                    data.chartConfig[
                      entry.name.toLowerCase().replace(/\s+/g, "")
                    ]?.color
                  }
                />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy}
                          className="fill-foreground text-xl font-bold"
                        >
                          {data.totalDefects}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 24}
                          className="fill-muted-foreground"
                        >
                          WO raised
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
          </PieChart>
        </ChartContainer>
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
          {top5DataWithPercentage.map((item) => (
            <div
              key={item.name}
              className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm transition-colors hover:bg-accent/10"
            >
              <div className="flex items-center space-x-2">
                <div
                  className="h-3 w-3 rounded-sm"
                  style={{
                    backgroundColor:
                      data.chartConfig[
                        item.name.toLowerCase().replace(/\s+/g, "")
                      ]?.color,
                  }}
                />
                <div className="flex flex-col">
                  <span className="text-xs font-medium text-foreground">
                    {item.name}
                  </span>
                  <span className="text-sm font-semibold">
                    {item.percentage}%
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        {chartDataWithPercentage.length > 5 && (
          <div className="mt-4 flex justify-center">
            <AppButton
              variant="outline"
              label="View All Types"
              onClick={() => setShowAllMetrics(true)}
            />
          </div>
        )}

        <Dialog open={showAllMetrics} onOpenChange={setShowAllMetrics}>
          <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>All Work Order Types</DialogTitle>
            </DialogHeader>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
              {chartDataWithPercentage.map((item) => (
                <div
                  key={item.name}
                  className="flex items-center space-x-2 rounded-lg bg-card p-2 shadow-sm"
                >
                  <div className="flex items-center space-x-2">
                    <div
                      className="h-3 w-3 rounded-sm"
                      style={{
                        backgroundColor:
                          data.chartConfig[
                            item.name.toLowerCase().replace(/\s+/g, "")
                          ]?.color,
                      }}
                    />
                    <div className="flex flex-col">
                      <span className="text-xs font-medium text-foreground">
                        {item.name}
                      </span>
                      <span className="text-sm font-semibold">
                        {item.percentage}% ({item.value})
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
}
