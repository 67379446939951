import { useSearchParams } from "react-router-dom";
import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import { PmStatusCountCard } from "./PmStatusCountCard";

export function PmStatusCountSection() {
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month") ?? "0")
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year") ?? "0")
    : undefined;
  const project = useAppSelector((state) => state.root.activeProject);
  const { data } = trpc.pm.getPmChecklistStatus.useQuery({
    projectId: project?.id ?? 0,
    month,
    year,
  });

  return (
    <>
      {data?.map((statusCount) => (
        <PmStatusCountCard key={statusCount.id} statusCount={statusCount} />
      ))}
    </>
  );
}
