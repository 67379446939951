import { useLocation } from "react-router-dom";

export default function AppBtnBarStagingNotice() {
  const currentUrl = window.location.origin;

  if(!currentUrl.includes('app-stag')) return (<></>)

  return (
    <div className="bottom-0 fixed w-screen bg-primary-900 z-10 text-white text-center font-bold">
      Cerev Demo Environment - For Training / Demo purpose only - PLEASE DO NOT
      KEY IN ACTUAL DATA
    </div>
  );
}
