import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthControllerGetCurrentUser } from "../../../api/auth/auth";
import { Company } from "../../../api/model";
import AppTextField from "../../AppTextField";
import { DataTable } from "../../react-table/DataTable";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setActiveCompany } from "../../../redux/slices/RootSlice";
import { Check } from "lucide-react";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FormProvider, useForm } from "react-hook-form";

export default function SelectCompanyDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const activeCompany = useAppSelector((state) => state.root.activeCompany);
  const { data: userData } = useAuthControllerGetCurrentUser({
    query: {
      select: (user) => user.data,
    },
  });

  const methods = useForm({
    defaultValues: {
      search: "",
    },
  });

  const columns: ColumnDef<Company>[] = [
    {
      id: "active",
      header: "",
      cell: ({ row }) => {
        const company = row.original;
        return activeCompany?.id === company.id ? (
          <Check className="size-4 text-primary-900" />
        ) : null;
      },
      size: 40,
    },
    {
      accessorKey: "name",
      header: "Company Name",
      filterFn: "includesString",
    },
    {
      accessorKey: "address",
      header: "Address",
      filterFn: "includesString",
    },
  ];

  const table = useReactTable({
    data: userData?.companies ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    state: {
      globalFilter: methods.watch("search"),
    },
    onGlobalFilterChange: (value) => {
      methods.setValue("search", value);
    },
    globalFilterFn: "includesString",
  });

  const handleCompanySelect = (company: Company) => {
    dispatch(
      setActiveCompany({
        comp: company,
        proj: userData?.projects.filter((p) => p.companyId === company.id)[0] ?? null,
      })
    );
    setSearchParams((p) => {
      p.delete("drawerType");
      return p;
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="space-y-4">
        <h2 className="text-2xl font-bold">Select Company</h2>
        <AppTextField
          name="search"
          placeholder="Search company..."
          onChange={(e) => table.setGlobalFilter(e.target.value)}
        />
        <DataTable
          table={table}
          totalCount={table.getFilteredRowModel().rows.length}
          pageIdx={table.getState().pagination.pageIndex}
          rowsPerPage={table.getState().pagination.pageSize}
          isFetching={false}
          isLoading={false}
          onRowClick={(row) => handleCompanySelect(row.original)}
        />
      </div>
    </FormProvider>
  );
} 