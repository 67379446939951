import { useSearchParams } from "react-router-dom";
import { useDefectControllerGetOneDefect } from "../../../api/work-orders-defects/work-orders-defects";
import useAppTabsNavigation from "../../../hooks/useAppTabsNavigation";
import DrawerFormSkeleton from "../../skeletons/DrawerFormSkeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import OthersTab from "./tabs/OthersTab/OthersTab";
import WorkOrderCommentTab from "./tabs/WorkOrderCommentTab/WorkOrderCommentTab";
import WorkOrderInfoTab from "./tabs/WorkOrderInfoTab/WorkOrderInfoTab";
import ManHourTab from "./tabs/ManHourTab/ManHourTab";
import ManHourGuard from "../../../guards/ManHourGuard";
import ProfitLossTab from "./tabs/ProfitLossTab/ProfitLossTab";
import WoProfitLossGuard from "../../../guards/WoProfitLossGuard";
import WorkOrderLogTab from "./tabs/WorkOrderLogTab/WorkOrderLogTab";
import { useEffect } from "react";
import ExtraFieldTab from "./tabs/ExtraFieldTab/ExtraFieldTab";
import ExtraFieldPricingGuard from "../../../guards/ExtraFieldPricingGuard";
import AppButton from "../../AppButton";
import { Pencil } from "lucide-react";
import { DRAWER_UPDATE_STATE, WORK_ORDER_FORM_DRAWER } from "../AppDrawer";
import useIsCloseWorkOrder from "../../../hooks/useIsCloseWorkOrder";
import { useHasWorkOrderEditAfterClosePolicy } from "../../../hooks/usePolicies/usePolicies";

export default function WorkOrderDetailDrawer() {
  const [searchParam, setSearchParams] = useSearchParams();
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "workOrderTabIdx",
    defaultValue: "0",
  });
  // const {currentTabIdx, setCurrentTabIdx} = useAppTabsNavigation({
  //   searchParamStr: "workOrderTabIdx",
  // });
  const activeWoId = searchParam.get("workOrderId");
  const { data: workOrder, isLoading } = useDefectControllerGetOneDefect(
    activeWoId ?? ""
  );

  const isClose = useIsCloseWorkOrder({ workOrder: workOrder?.data });
  const hasEditAfterClosePolicy = useHasWorkOrderEditAfterClosePolicy();

  // const handleChange = (event: React.SyntheticEvent, newTabIdx: number) => {
  //   setCurrentTabIdx(newTabIdx);
  // };

  if (isLoading && !workOrder) return <DrawerFormSkeleton />;

  return (
    <div className="flex flex-col gap-4 relative">
      <div className="flex gap-4 items-center">
        <p className="font-sans text-2xl font-bold">{workOrder?.data?.refNo}</p>
        {(!isClose || hasEditAfterClosePolicy) && (
          <AppButton
            label="Edit"
            variant="outline"
            icon={<Pencil />}
            onClick={() => {
              setSearchParams((p) => {
                p.set("drawerType", WORK_ORDER_FORM_DRAWER);
                p.set("drawerState", DRAWER_UPDATE_STATE);
                p.set("workOrderId", workOrder?.data?.id?.toString() ?? "");
                return p;
              });
            }}
          />
        )}
      </div>
      <div className="col-span-2">
        <Tabs
          value={currentTabIdx}
          onValueChange={onTabChange}
          defaultValue="0"
        >
          <TabsList className="mb-4">
            <TabsTrigger value="0">
              <p>Info</p>
            </TabsTrigger>
            <TabsTrigger value="1">
              <p>Comments</p>
            </TabsTrigger>
            <TabsTrigger value="2">
              <p>Logs</p>
            </TabsTrigger>
            <TabsTrigger value="3">
              <p>Others</p>
            </TabsTrigger>
            <ManHourGuard>
              <TabsTrigger value="4">
                <p>Man Hours</p>
              </TabsTrigger>
            </ManHourGuard>
            <WoProfitLossGuard>
              <TabsTrigger value="5">
                <p>P&L</p>
              </TabsTrigger>
            </WoProfitLossGuard>
            <ExtraFieldPricingGuard>
              <TabsTrigger value="6">
                <p>Costing</p>
              </TabsTrigger>
            </ExtraFieldPricingGuard>
          </TabsList>
          <TabsContent value="0">
            <WorkOrderInfoTab workOrder={workOrder?.data} />
          </TabsContent>
          <TabsContent value="1">
            <WorkOrderCommentTab workOrder={workOrder?.data} />
          </TabsContent>
          <TabsContent value="2">
            <WorkOrderLogTab />
          </TabsContent>
          <TabsContent value="3">
            <OthersTab workOrder={workOrder?.data} />
          </TabsContent>
          <ManHourGuard>
            <TabsContent value="4">
              <ManHourTab />
            </TabsContent>
          </ManHourGuard>
          <TabsContent value="5">
            <ProfitLossTab />
          </TabsContent>
          <ExtraFieldPricingGuard>
            <TabsContent value="6">
              <ExtraFieldTab />
            </TabsContent>
          </ExtraFieldPricingGuard>
        </Tabs>
      </div>
    </div>
  );
}
