import { CerevModule } from "../../api/model";
import { Tabs } from "../../components/ui/tabs";
import ModuleAccessGuard from "../../guards/ModuleAccessGuard";
import useAppTabsNavigation from "../../hooks/useAppTabsNavigation";
import { useAppSelector } from "../../redux/store";
import AppTitle from "../HomeScreen/components/AppTitle";
import { getDashboardComponent } from "./dashboards";

export default function DashboardScreen() {
  const { currentTabIdx, onTabChange } = useAppTabsNavigation({
    searchParamStr: "dashboardTabIdx",
    defaultValue: "0",
  });
  
  const activeProj = useAppSelector((state) => state.root.activeProject);

  if (!activeProj) return <div>No active project selected</div>;
  
  const dashboardConfig = getDashboardComponent(activeProj.code);
  const DashboardComponent = dashboardConfig.component;

  return (
    <ModuleAccessGuard module={CerevModule.DASHBOARD}>
      <AppTitle title="Dashboard" />
      <Tabs value={currentTabIdx} onValueChange={onTabChange}>
        <DashboardComponent currentTabIdx={currentTabIdx} />
      </Tabs>
    </ModuleAccessGuard>
  );
}
