import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../../../ui/checkbox";
import { RouterOutputs } from "@cerev-cmms/trpc";

type WorkRequest = RouterOutputs["workRequest"]["getWorkRequests"][number];

export const linkWorkRequestColumns: ColumnDef<WorkRequest>[] = [
  {
    accessorKey: "id",
  },
  {
    header: "",
    id: "select",
    cell: ({ row, table }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value);
        }}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "location",
    header: "Location",
  }
];
