import { z } from "zod";

export const createCustomProjectAnalyticSchema = z.object({
  projectId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  value: z.any().optional(), // Using any since value is Json type in prisma
});

export const updateCustomProjectAnalyticSchema = z.object({
  id: z.number(),
  projectId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  value: z.any().optional(),
});

export const getOneCustomProjectAnalyticSchema = z.object({
  projectId: z.string(),
  name: z.string(),
  dateStart: z.string(), // ISO string date
  dateEnd: z.string(), // ISO string date
});

export const upsertCustomProjectAnalyticSchema = z.object({
  projectId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  value: z.any(),
  date: z.string(), // ISO string date
});

export type CreateCustomProjectAnalyticInput = z.infer<typeof createCustomProjectAnalyticSchema>;
export type UpdateCustomProjectAnalyticInput = z.infer<typeof updateCustomProjectAnalyticSchema>;
export type GetOneCustomProjectAnalyticInput = z.infer<typeof getOneCustomProjectAnalyticSchema>;
export type UpsertCustomProjectAnalyticInput = z.infer<typeof upsertCustomProjectAnalyticSchema>;
