import React from "react";
import { TabsList, TabsTrigger, TabsContent } from "../../../components/ui/tabs";
import AppIFrame from "./AppIframe";
import { trpc } from "../../../lib/trpc";
import { useAppSelector } from "../../../redux/store";
import { Error } from "@mui/icons-material";

interface DefaultDashboardProps {
  currentTabIdx: string;
}

const DefaultDashboard: React.FC<DefaultDashboardProps> = ({ currentTabIdx }) => {
  const activeProj = useAppSelector((state) => state.root.activeProject);
  const { data, isLoading } = trpc.analytics.getAnalyticsReport.useQuery(
    {
      projectId: activeProj?.id ?? 0,
    },
    {
      enabled: !!activeProj,
    }
  );

  if (isLoading) return <div>Loading...</div>;

  if (!data || data.length === 0) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <div className="flex flex-col items-center">
          <Error className="text-neutral-500 w-20 h-20" />
          <p className="text-neutral-500 mt-4">
            Unfortunately, there is no analytics setup for this project
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <TabsList>
        {data.map((an, idx) => (
          <TabsTrigger key={an.iframeUrl} value={idx.toString()}>
            <p>{an.settings.tabName}</p>
          </TabsTrigger>
        ))}
      </TabsList>
      {data.map((an, idx) => (
        <TabsContent key={an.iframeUrl} value={idx.toString()}>
          <AppIFrame
            analyticRes={an}
            tabIdx={Number(currentTabIdx)}
            idx={idx}
            key={an.iframeUrl}
          />
        </TabsContent>
      ))}
    </>
  );
};

export default DefaultDashboard;
