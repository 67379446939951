import { DateFilter } from "../WorkRequestAnalytics/DateFilter";
import { RemedialExpensesCategoryPieChart } from "./RemedialExpensesCategoryPieChart";
import RemedialExpensesTrendChart from "./RemedialExpensesTrendChart";

export default function ExpensesAnalytic() {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between mt-2 col-span-2">
        <DateFilter />
      </div>
      <div className="col-span-2">
        <RemedialExpensesCategoryPieChart />
      </div>
      <div className="col-span-2">
        <RemedialExpensesTrendChart />
      </div>
    </div>
  );
}
