import { RouterOutputs } from "@cerev-cmms/trpc";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { DRAWER_VIEW_STATE, LEGAL_COMPLIANCE_DETAIL_DRAWER } from "@/components/drawers/AppDrawer";

type ExpiredContract = {
  id: number;
  name: string;
  description: string | null;
  expiryDate: Date;
};

export const useColumns = () => {
  const [, setSearchParams] = useSearchParams();

  const columns: ColumnDef<ExpiredContract>[] = [
    {
      accessorKey: "index",
      header: "No.",
      cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => (
        <span
          className="cursor-pointer underline text-primary hover:text-primary/80"
          onClick={() => {
            setSearchParams((params) => {
              params.set("drawerType", LEGAL_COMPLIANCE_DETAIL_DRAWER);
              params.set("drawerState", DRAWER_VIEW_STATE);
              params.set("legalComplianceId", row.original.id.toString());
              return params;
            });
          }}
        >
          {row.original.name}
        </span>
      ),
    },
    {
      accessorKey: "description",
      header: "Description",
      cell: ({ row }) => row.original.description || "-",
    },
    {
      accessorKey: "expiryDate",
      header: "Expiry Date",
      cell: ({ row }) => format(new Date(row.original.expiryDate), "dd MMM yyyy"),
    },
  ];

  return columns;
}; 