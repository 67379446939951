import React, { useRef } from "react";
import { Card } from "@/components/ui/card";
import { format, addMonths, subMonths } from "date-fns";
import { trpc } from "../../../lib/trpc";
import { KpiCard } from "./KpiCard";
import { useReactToPrint } from "react-to-print";
import AppButton from "../../../components/AppButton";
import { Printer, ChevronLeft, ChevronRight } from "lucide-react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/store";

interface KpiValues {
  [key: string]: number;
}

interface KpiData {
  code: string;
  description: string;
  value: number;
  threshold: number;
}

export default function JkrKpiReport() {
  const utils = trpc.useContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeProject = useAppSelector(state => state.root.activeProject);
  
  // Get the date from URL or default to current month
  const dateParam = searchParams.get('date');
  const currentDate = dateParam ? new Date(dateParam) : new Date();
  
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const { data: analyticsData, isLoading } = trpc.pdfReports.getOneCustomProjectAnalytic.useQuery({
    projectId: activeProject?.id?.toString() ?? "",
    name: "JKR_KPI",
    dateStart: firstDayOfMonth.toISOString(),
    dateEnd: lastDayOfMonth.toISOString(),
  });

  const upsertMutation = trpc.pdfReports.upsertCustomProjectAnalytic.useMutation({
    onSuccess: () => {
      utils.pdfReports.getOneCustomProjectAnalytic.invalidate();
    },
  });

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    pageStyle: "@page { size: auto; margin: 0.5in; }",
    copyShadowRoots: true,
  });

  const handleMonthChange = (direction: 'prev' | 'next') => {
    const newDate = direction === 'prev' 
      ? subMonths(currentDate, 1)
      : addMonths(currentDate, 1);
    
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('date', newDate.toISOString());
    setSearchParams(newSearchParams);
  };

  if (isLoading) return <div>Loading...</div>;

  const kpiValues = (analyticsData?.data?.value as KpiValues) ?? {};

  const kpis: KpiData[] = [
    {
      code: "KPI 1A",
      description: "Customer Satisfaction Survey Rating > 80%",
      value: kpiValues["KPI 1A"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 2C",
      description: "Electrical PM Checklist Completion",
      value: kpiValues["KPI 2C"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 1B",
      description: "Defect Rating",
      value: kpiValues["KPI 1B"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 2D",
      description: "Engineering Reports & Recommendation Action 100% taken",
      value: kpiValues["KPI 2D"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 1C",
      description: "Defect Creation Time < 15 mins",
      value: kpiValues["KPI 1C"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 2E",
      description:
        "Work-done as specification and asset quality meet standards",
      value: kpiValues["KPI 2E"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 1D",
      description: "Execution Time > 95%",
      value: kpiValues["KPI 1D"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 3A",
      description: "Energy Conservation Programs",
      value: kpiValues["KPI 3A"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 1E",
      description: "Pending / Backlog Work Order Completion 100%",
      value: kpiValues["KPI 1E"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 3B",
      description: "Building Energy Index (BEI)",
      value: kpiValues["KPI 3B"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 1F",
      description: "Self Finding Work Order > 80% from Total Work Order",
      value: kpiValues["KPI 1F"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 3C",
      description: "Utility Consumption 100% No Wastage",
      value: kpiValues["KPI 3C"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 1G",
      description: "Critical Services",
      value: kpiValues["KPI 1G"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 4A",
      description: "Relevant Acts & Regulations 100% Comply",
      value: kpiValues["KPI 4A"] ?? 0,
      threshold: 80,
    },
    { code: "KPI 1H", description: "Normal Services", value: kpiValues["KPI 1H"] ?? 0, threshold: 80 },
    {
      code: "KPI 4B",
      description: "HSE Programs 100% implemented",
      value: kpiValues["KPI 4B"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 2A",
      description: "PPM For Architecture and C&S assets",
      value: kpiValues["KPI 2A"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 4C",
      description: "Reports submitted 100% on time with sufficient content",
      value: kpiValues["KPI 4C"] ?? 0,
      threshold: 80,
    },
    {
      code: "KPI 2B",
      description: "PPM for Mechanical assets",
      value: kpiValues["KPI 2B"] ?? 0,
      threshold: 80,
    },
  ];

  const achievedKpis = kpis.filter((kpi) => kpi.value >= kpi.threshold).length;
  const failedKpis = kpis.length - achievedKpis;

  const handleKpiUpdate = (kpiCode: string, newValue: number) => {
    const updatedValues: KpiValues = {
      ...kpiValues,
      [kpiCode]: newValue,
    };

    upsertMutation.mutate({
      projectId: "1",
      name: "JKR_KPI",
      description: "JKR KPI Report Values",
      value: updatedValues,
      date: currentDate.toISOString(),
    });
  };

  return (
    <div>
      <div className="space-y-4" ref={contentRef}>
        <div className="flex items-center justify-between">
          <AppButton
            variant="ghost"
            className="p-2"
            onClick={() => handleMonthChange('prev')}
            icon={<ChevronLeft className="h-4 w-4" />}
          />
          <h2 className="text-2xl font-bold text-center">
            {format(currentDate, "MMMM yyyy")}
          </h2>
          <div className="flex gap-2">
            <AppButton
              variant="ghost"
              className="p-2"
              onClick={() => handleMonthChange('next')}
              icon={<ChevronRight className="h-4 w-4" />}
            />
            <AppButton
              className="print:hidden ml-2"
              variant="outline"
              icon={<Printer className="w-4 h-4" />}
              onClick={() => reactToPrintFn()}
            >
              Print
            </AppButton>
          </div>
        </div>

        <div className="flex space-x-4">
          <Card className="w-full flex gap-20 items-center justify-center py-4 shadow-none">
            <div className="flex flex-col gap-4 items-center">
              <p className="font-medium">Failed KPIs</p>
              <p className="text-2xl font-bold">{failedKpis}</p>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <p className="font-medium">Achieved KPIs</p>
              <p className="text-2xl font-bold">{achievedKpis}</p>
            </div>
          </Card>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {kpis.map((kpi) => (
            <KpiCard
              key={kpi.code}
              kpiCode={kpi.code}
              description={kpi.description}
              value={kpi.value}
              threshold={kpi.threshold}
              onEdit={(newValue) => handleKpiUpdate(kpi.code, newValue)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
