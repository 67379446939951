import { ChevronsUpDown } from "lucide-react";
import { CerevLogo } from "../../../../../icons/CerevLogo";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import { Project } from "../../../../../redux/slices/OpenApi/cerevApi";
import useCurrentUser from "../../../../../hooks/useCurrentUser/useCurrentUser";
import { setActiveProject } from "../../../../../redux/slices/RootSlice";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../../../../components/ui/dropdown-menu";
import {
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "../../../../../components/ui/sidebar";
import { Building } from "lucide-react";

export function CerevSidebarHeader() {
  const dispatch = useAppDispatch();
  const activeCompany = useAppSelector((state) => state.root.activeCompany);
  const activeProject = useAppSelector((state) => state.root.activeProject);
  const user = useCurrentUser();

  const handleProjectSelect = (project: Project) => {
    dispatch(setActiveProject(project));
  };

  const filteredProjects = user?.data?.projects?.filter(
    (p) => p.companyId === activeCompany?.id
  );

  return (
    <SidebarHeader>
      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton size="lg" variant="outline" className="bg-transparent">
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-transparent text-sidebar-primary-foreground">
                  <CerevLogo />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {activeCompany?.name}
                  </span>
                  <span className="truncate text-xs">
                    {activeProject?.name}
                  </span>
                </div>
                <ChevronsUpDown className="ml-auto size-4" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              sideOffset={4}
              align="start"
              side="right"
            >
              <DropdownMenuLabel className="text-xs text-muted-foreground">
                Projects
              </DropdownMenuLabel>
              {filteredProjects?.map((project) => (
                <DropdownMenuItem
                  key={project.id}
                  className="gap-2 p-2"
                  onClick={() => handleProjectSelect(project)}
                >
                  <div className="flex size-6 items-center justify-center rounded-sm border">
                    <Building className="size-4 shrink-0" />
                  </div>
                  {project.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarHeader>
  );
} 