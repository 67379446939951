import {
  Sidebar,
  SidebarContent,
  SidebarProvider,
} from "../../../../components/ui/sidebar";
import { CerevSidebarHeader } from "./components/CerevSidebarHeader";
import { CerevSidebarFooter } from "./components/CerevSidebarFooter";
import { CerevSidebarContent } from "./components/CerevSidebarNavigation";
import { useLocation } from "react-router-dom";

export function CerevSidebar() {
  const location = useLocation();

  const isSettingScreen = location.pathname.includes("/home/settings");
  const isSuperadminScreen = location.pathname.includes("/super-admin");

  if (isSettingScreen || isSuperadminScreen) return <></>;

  return (
    <Sidebar collapsible="icon">
      <CerevSidebarHeader />
      <CerevSidebarContent />
      <CerevSidebarFooter />
    </Sidebar>
  );
}
