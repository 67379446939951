import React from "react";
import { trpc } from "../../../../lib/trpc";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { useAppSelector } from "../../../../redux/store";
import { Progress } from "@/components/ui/progress";
import { useSearchParams } from "react-router-dom";

export function WorkRequestAvgRating() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month") ?? "")
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year") ?? "")
    : undefined;

  const { data, isLoading } = trpc.analytics.getAvgWorkRequestRating.useQuery(
    {
      projectId: project?.id ?? 0,
      month,
      year,
    },
    {
      enabled: !!project,
    }
  );

  if (isLoading) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            <Loader2 className="h-12 w-12 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card>
        <CardContent className="pt-6">
          <div className="w-full min-h-[275px] flex items-center justify-center">
            No work request rating data available
          </div>
        </CardContent>
      </Card>
    );
  }

  const ratingPercentage = (data.averageRating / 5) * 100; // Assuming rating is out of 5

  return (
    <Card>
      <CardHeader>
        <CardTitle>Work Request Average Rating</CardTitle>
        <CardDescription>
          Average rating of completed work requests
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col items-center space-y-4 justify-center mt-24">
        <div className="text-4xl font-bold">
          {data.averageRating.toFixed(2)}
        </div>
        <Progress value={ratingPercentage} className="w-full h-2" />
        <div className="text-sm text-muted-foreground">
          Based on {data.totalRatedRequests} rated requests
        </div>
      </CardContent>
    </Card>
  );
}
