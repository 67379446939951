import { trpc } from "../../../../lib/trpc";
import { useAppSelector } from "../../../../redux/store";
import { WorkOrderStatusCountCard } from "./WorkOrderStatusCountCard";
import { useSearchParams } from "react-router-dom";

export function WorkOrderStatusCountSection() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;
  const { data } = trpc.workOrders.getWorkOrderStatus.useQuery({
    projectId: project?.id ?? 0,
    month,
    year,
  });

  return (
    <>
      {data?.map((statusCount) => (
        <WorkOrderStatusCountCard statusCount={statusCount} key={statusCount.name} />
      ))}
    </>
  );
}
