import { useAppSelector } from "../../../../redux/store";
import { trpc } from "../../../../lib/trpc";
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DataTable } from "../../../../components/react-table/DataTable";
import { useState } from "react";
import { columns } from "./columns";
import { useSearchParams } from "react-router-dom";

type AssetPPMData = {
  name: string;
  value: number;
};

export function AssetPPMCompletionRateTable() {
  const project = useAppSelector((state) => state.root.activeProject);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month")
    ? parseInt(searchParams.get("month")!)
    : undefined;
  const year = searchParams.get("year")
    ? parseInt(searchParams.get("year")!)
    : undefined;

  const { data, isLoading } = trpc.analytics.getAssetPPMCompletionRate.useQuery({
    projectId: project?.id ?? 0,
    month,
    year,
  });

  const table = useReactTable({
    data: data?.chartData ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: Math.ceil((data?.chartData.length ?? 0) / pageSize),
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater({
          pageIndex,
          pageSize,
        });
        setPageIndex(newState.pageIndex);
        setPageSize(newState.pageSize);
      } else {
        setPageIndex(updater.pageIndex);
        setPageSize(updater.pageSize);
      }
    },
    manualPagination: false,
  });

  return (
    <div className="rounded-lg border bg-white p-4">
      <h2 className="mb-4 text-lg font-semibold">Asset PPM Completion Rates</h2>
      <DataTable
        table={table}
        totalCount={data?.chartData.length ?? 0}
        pageIdx={pageIndex}
        rowsPerPage={pageSize}
        isLoading={isLoading}
      />
    </div>
  );
}
