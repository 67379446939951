import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";

const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export function DateFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

  const selectedMonth = searchParams.get("month");
  const selectedYear = searchParams.get("year");

  const handleMonthChange = (value: string) => {
    searchParams.set("month", value);
    if (!selectedYear) {
      searchParams.set("year", currentYear.toString());
    }
    setSearchParams(searchParams);
  };

  const handleYearChange = (value: string) => {
    searchParams.set("year", value);
    if (!selectedMonth) {
      searchParams.set("month", (new Date().getMonth() + 1).toString());
    }
    setSearchParams(searchParams);
  };

  const handleClear = () => {
    searchParams.delete("month");
    searchParams.delete("year");
    setSearchParams(searchParams);
  };

  const isFilterActive = selectedMonth !== null || selectedYear !== null;

  return (
    <div className="flex items-center gap-2 px-1">
      <Select
        value={selectedMonth || ""}
        onValueChange={handleMonthChange}
      >
        <SelectTrigger className="w-[140px]">
          <SelectValue placeholder="Select month" />
        </SelectTrigger>
        <SelectContent>
          {months.map((month) => (
            <SelectItem key={month.value} value={month.value}>
              {month.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <Select
        value={selectedYear || ""}
        onValueChange={handleYearChange}
      >
        <SelectTrigger className="w-[120px]">
          <SelectValue placeholder="Select year" />
        </SelectTrigger>
        <SelectContent>
          {years.map((year) => (
            <SelectItem key={year} value={year.toString()}>
              {year}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {isFilterActive && (
        <Button
          variant="ghost"
          size="icon"
          onClick={handleClear}
          className="h-8 w-8"
        >
          <X className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
} 