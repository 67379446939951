import { RouterOutputs } from "@cerev-cmms/trpc";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { DRAWER_VIEW_STATE, LEGAL_COMPLIANCE_DETAIL_DRAWER } from "@/components/drawers/AppDrawer";

type FMContract = {
  id: number;
  name: string;
  description: string | null;
  expiryDate: Date;
  createdOn: Date;
};

export const useColumns = () => {
  const [, setSearchParams] = useSearchParams();

  const columns: ColumnDef<FMContract>[] = [
    {
      accessorKey: "index",
      header: "No.",
      cell: ({ row }) => row.index + 1,
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => (
        <span
          className="cursor-pointer underline text-primary hover:text-primary/80"
          onClick={() => {
            setSearchParams((params) => {
              params.set("drawerType", LEGAL_COMPLIANCE_DETAIL_DRAWER);
              params.set("drawerState", DRAWER_VIEW_STATE);
              params.set("legalComplianceId", row.original.id.toString());
              return params;
            });
          }}
        >
          {row.original.name}
        </span>
      ),
    },
    {
      accessorKey: "description",
      header: "Description",
      cell: ({ row }) => row.original.description || "-",
    },
    {
      accessorKey: "expiryDate",
      header: "Expiry Date",
      cell: ({ row }) => format(new Date(row.original.expiryDate), "dd MMM yyyy"),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const isExpired = new Date(row.original.expiryDate) <= new Date();
        return (
          <span
            className={`rounded-full px-2 py-1 text-xs font-medium ${
              isExpired
                ? "bg-red-100 text-red-800"
                : "bg-green-100 text-green-800"
            }`}
          >
            {isExpired ? "Expired" : "Active"}
          </span>
        );
      },
    },
  ];

  return columns;
}; 